export const FETCH_PARTICIPANT_CERTIFICATE_LIST_START =
  'FETCH_PARTICIPANT_CERTIFICATE_LIST_START';
export const FETCH_PARTICIPANT_CERTIFICATE_LIST_SUCCESS =
  'FETCH_PARTICIPANT_CERTIFICATE_LIST_SUCCESS';
export const FETCH_PARTICIPANT_CERTIFICATE_LIST_FAIL =
  'FETCH_PARTICIPANT_CERTIFICATE_LIST_FAIL';

export const FETCH_PUBLIC_CERTIFICATE_START = 'FETCH_PUBLIC_CERTIFICATE_START';
export const FETCH_PUBLIC_CERTIFICATE_SUCCESS =
  'FETCH_PUBLIC_CERTIFICATE_SUCCESS';
export const FETCH_PUBLIC_CERTIFICATE_FAIL = 'FETCH_PUBLIC_CERTIFICATE_FAIL';

export const PREPARE_TO_SIGN_CERTIFICATE_START =
  'PREPARE_TO_SIGN_CERTIFICATE_START';
export const PREPARE_TO_SIGN_CERTIFICATE_SUCCESS =
  'PREPARE_TO_SIGN_CERTIFICATE_SUCCESS';
export const PREPARE_TO_SIGN_CERTIFICATE_FAIL =
  'PREPARE_TO_SIGN_CERTIFICATE_FAIL';
export const PREPARE_TO_SIGN_CERTIFICATE_RESET =
  'PREPARE_TO_SIGN_CERTIFICATE_RESET';

export const SIGN_ON_BEHALF_START = 'SIGN_ON_BEHALF_START';
export const SIGN_ON_BEHALF_SUCCESS = 'SIGN_ON_BEHALF_SUCCESS';
export const SIGN_ON_BEHALF_FAIL = 'SIGN_ON_BEHALF_FAIL';
export const RESET_SIGN_ON_BEHALF = 'RESET_SIGN_ON_BEHALF';

export const START_SIGN_PROCESS_START = 'START_SIGN_PROCESS_START';
export const START_SIGN_PROCESS_SUCCESS = 'START_SIGN_PROCESS_SUCCESS';
export const START_SIGN_PROCESS_FAIL = 'START_SIGN_PROCESS_FAIL';

export const GET_SIGN_URL_START = 'GET_SIGN_URL_START';
export const GET_SIGN_URL_SUCCESS = 'GET_SIGN_URL_SUCCESS';
export const GET_SIGN_URL_FAIL = 'GET_SIGN_URL_FAIL';

export const BIOMETRIC_SIGN_START = 'BIOMETRIC_SIGN_START';
export const BIOMETRIC_SIGN_SUCCESS = 'BIOMETRIC_SIGN_SUCCESS';
export const BIOMETRIC_SIGN_FAIL = 'BIOMETRIC_SIGN_FAIL';

export const CHECK_SIGN_STATUS_START = 'CHECK_SIGN_STATUS_START';
export const CHECK_SIGN_STATUS_SUCCESS = 'CHECK_SIGN_STATUS_SUCCESS';
export const CHECK_SIGN_STATUS_FAIL = 'CHECK_SIGN_STATUS_FAIL';

export const SEND_CERTIFICATE_START = 'SEND_CERTIFICATE_START';
export const SEND_CERTIFICATE_SUCCESS = 'SEND_CERTIFICATE_SUCCESS';
export const SEND_CERTIFICATE_FAIL = 'SEND_CERTIFICATE_FAIL';
export const RESET_SEND_CERTIFICATE_STORE = 'RESET_SEND_CERTIFICATE_STORE';

export const CREATE_EXTERNAL_CERTIFICATE_START =
  'CREATE_EXTERNAL_CERTIFICATE_START';
export const CREATE_EXTERNAL_CERTIFICATE_SUCCESS =
  'CREATE_EXTERNAL_CERTIFICATE_SUCCESS';
export const CREATE_EXTERNAL_CERTIFICATE_FAIL =
  'CREATE_EXTERNAL_CERTIFICATE_FAIL';

export const UPDATE_EXTERNAL_CERTIFICATE_START =
  'UPDATE_EXTERNAL_CERTIFICATE_START';
export const UPDATE_EXTERNAL_CERTIFICATE_SUCCESS =
  'UPDATE_EXTERNAL_CERTIFICATE_SUCCESS';
export const UPDATE_EXTERNAL_CERTIFICATE_FAIL =
  'UPDATE_EXTERNAL_CERTIFICATE_FAIL';

export const DELETE_EXTERNAL_CERTIFICATE_START =
  'DELETE_EXTERNAL_CERTIFICATE_START';
export const DELETE_EXTERNAL_CERTIFICATE_SUCCESS =
  'DELETE_EXTERNAL_CERTIFICATE_SUCCESS';
export const DELETE_EXTERNAL_CERTIFICATE_FAIL =
  'DELETE_EXTERNAL_CERTIFICATE_FAIL';

export const FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_START =
  'FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_START';
export const FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_SUCCESS =
  'FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_SUCCESS';
export const FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_FAIL =
  'FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_FAIL';

export const FETCH_PARTICIPANT_SECTION_CERTIFICATES_START =
  'FETCH_PARTICIPANT_SECTION_CERTIFICATES_START';
export const FETCH_PARTICIPANT_SECTION_CERTIFICATES_SUCCESS =
  'FETCH_PARTICIPANT_SECTION_CERTIFICATES_SUCCESS';
export const FETCH_PARTICIPANT_SECTION_CERTIFICATES_FAIL =
  'FETCH_PARTICIPANT_SECTION_CERTIFICATES_FAIL';

export const TOGGLE_PARTICIPANT_CERTIFICATE_START =
  'TOGGLE_PARTICIPANT_CERTIFICATE_START';
export const TOGGLE_PARTICIPANT_CERTIFICATE_SUCCESS =
  'TOGGLE_PARTICIPANT_CERTIFICATE_SUCCESS';
export const TOGGLE_PARTICIPANT_CERTIFICATE_FAIL =
  'TOGGLE_PARTICIPANT_CERTIFICATE_FAIL';

export const TOGGLE_CERTIFICATE_ARCHIVE_STATUS_START =
  'TOGGLE_CERTIFICATE_ARCHIVE_STATUS_START';
export const TOGGLE_CERTIFICATE_ARCHIVE_STATUS_SUCCESS =
  'TOGGLE_CERTIFICATE_ARCHIVE_STATUS_SUCCESS';
export const TOGGLE_CERTIFICATE_ARCHIVE_STATUS_FAIL =
  'TOGGLE_CERTIFICATE_ARCHIVE_STATUS_FAIL';

export const TOGGLE_CERTIFICATE_VIEWED_STATUS_START =
  'TOGGLE_CERTIFICATE_VIEWED_STATUS_START';
export const TOGGLE_CERTIFICATE_VIEWED_STATUS_SUCCESS =
  'TOGGLE_CERTIFICATE_VIEWED_STATUS_SUCCESS';
export const TOGGLE_CERTIFICATE_VIEWED_STATUS_FAIL =
  'TOGGLE_CERTIFICATE_VIEWED_STATUS_FAIL';

export const RESET_SIGNED_CERTIFICATE_STATUS =
  'RESET_SIGNED_CERTIFICATE_STATUS';

export const RESET_BIOMETRIC_SIGN_STORE = 'RESET_BIOMETRIC_SIGN_STORE';

export const RESET_PARTICIPANT_CERTIFICATE_LIST_STORE =
  'RESET_PARTICIPANT_CERTIFICATE_LIST_STORE';

export const RESET_EXTERNAL_CERTIFICATE_CREATE =
  'RESET_EXTERNAL_CERTIFICATE_CREATE';

export const RESET_CERTIFICATE_STORE = 'RESET_CERTIFICATE_STORE';

export type CertificateActionTypes =
  | typeof FETCH_PARTICIPANT_CERTIFICATE_LIST_START
  | typeof FETCH_PARTICIPANT_CERTIFICATE_LIST_SUCCESS
  | typeof FETCH_PARTICIPANT_CERTIFICATE_LIST_FAIL
  | typeof FETCH_PUBLIC_CERTIFICATE_START
  | typeof FETCH_PUBLIC_CERTIFICATE_SUCCESS
  | typeof FETCH_PUBLIC_CERTIFICATE_FAIL
  | typeof PREPARE_TO_SIGN_CERTIFICATE_START
  | typeof PREPARE_TO_SIGN_CERTIFICATE_SUCCESS
  | typeof PREPARE_TO_SIGN_CERTIFICATE_FAIL
  | typeof PREPARE_TO_SIGN_CERTIFICATE_RESET
  | typeof GET_SIGN_URL_START
  | typeof GET_SIGN_URL_SUCCESS
  | typeof GET_SIGN_URL_FAIL
  | typeof START_SIGN_PROCESS_START
  | typeof START_SIGN_PROCESS_SUCCESS
  | typeof START_SIGN_PROCESS_FAIL
  | typeof SEND_CERTIFICATE_START
  | typeof SEND_CERTIFICATE_SUCCESS
  | typeof SEND_CERTIFICATE_FAIL
  | typeof BIOMETRIC_SIGN_START
  | typeof BIOMETRIC_SIGN_SUCCESS
  | typeof BIOMETRIC_SIGN_FAIL
  | typeof CHECK_SIGN_STATUS_START
  | typeof CHECK_SIGN_STATUS_SUCCESS
  | typeof CHECK_SIGN_STATUS_FAIL
  | typeof CREATE_EXTERNAL_CERTIFICATE_START
  | typeof CREATE_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof CREATE_EXTERNAL_CERTIFICATE_FAIL
  | typeof UPDATE_EXTERNAL_CERTIFICATE_START
  | typeof UPDATE_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof UPDATE_EXTERNAL_CERTIFICATE_FAIL
  | typeof DELETE_EXTERNAL_CERTIFICATE_START
  | typeof DELETE_EXTERNAL_CERTIFICATE_SUCCESS
  | typeof DELETE_EXTERNAL_CERTIFICATE_FAIL
  | typeof FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_START
  | typeof FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_SUCCESS
  | typeof FETCH_UNSECTIONED_PARTICIPANT_CERTIFICATES_FAIL
  | typeof FETCH_PARTICIPANT_SECTION_CERTIFICATES_START
  | typeof FETCH_PARTICIPANT_SECTION_CERTIFICATES_SUCCESS
  | typeof FETCH_PARTICIPANT_SECTION_CERTIFICATES_FAIL
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_START
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_SUCCESS
  | typeof TOGGLE_PARTICIPANT_CERTIFICATE_FAIL
  | typeof TOGGLE_CERTIFICATE_ARCHIVE_STATUS_START
  | typeof TOGGLE_CERTIFICATE_ARCHIVE_STATUS_SUCCESS
  | typeof TOGGLE_CERTIFICATE_ARCHIVE_STATUS_FAIL
  | typeof TOGGLE_CERTIFICATE_VIEWED_STATUS_START
  | typeof TOGGLE_CERTIFICATE_VIEWED_STATUS_SUCCESS
  | typeof TOGGLE_CERTIFICATE_VIEWED_STATUS_FAIL
  | typeof SIGN_ON_BEHALF_START
  | typeof SIGN_ON_BEHALF_SUCCESS
  | typeof SIGN_ON_BEHALF_FAIL
  | typeof RESET_SIGN_ON_BEHALF
  | typeof RESET_SEND_CERTIFICATE_STORE
  | typeof RESET_PARTICIPANT_CERTIFICATE_LIST_STORE
  | typeof RESET_PARTICIPANT_CERTIFICATE_LIST_STORE
  | typeof RESET_SIGNED_CERTIFICATE_STATUS
  | typeof RESET_BIOMETRIC_SIGN_STORE
  | typeof RESET_EXTERNAL_CERTIFICATE_CREATE
  | typeof RESET_CERTIFICATE_STORE;
